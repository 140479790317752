import React from "react"
import PortraitImage from "../components/image"

import styled, { ThemeProvider } from "styled-components";

import Layout from "../components/layout"

const ImageContainer = styled.div`
  margin: 10% 15%;
`;

const AboutPage = (props) => (
  <Layout pageTitle="Über Mich" buttonLink="/kontakt/" buttonTitle="Kontakt">
    <h1>Über Mich</h1>
    <ImageContainer>
      <PortraitImage />
    </ImageContainer>
    <div>
      <div>
        <p>Schon als junges Mädchen liebte ich es, mich zu bewegen. So zeichnete sich meine Leidenschaft für das Turmspringen schon früh ab.</p>
        <p>Die Kombination von Wasser und absoluter Körperbeherrschung inspirierte mich. Jeder Sprung war eine neue Herausforderung – konzentrierter Absprung, kontrollierter Flug, sauberes Eintauchen – eine Kombination aus Respekt, Mut und Faszination.</p>
        <p>Nach meiner Karriere als Turmspringerin, die ich 2009 nach 8 Schweizermeister Titeln und Teilnahmen an Weltmeisterschaften und Europameisterschaften beendete, habe ich mir nebenbei kontinuierlich ein zweites Standbein aufgebaut.</p>
        <p>Nach meiner Grundausbildung zur Polymechanikerin fand ich auch auf dem beruflichen Weg zurück zum Sport, in dem ich mich zur Sporttherapeutin umgeschult habe.</p>
        <h2 class="highlight-text">Mein Ziel</h2>
        <p>Menschen durch Sport und Therapie ein Bewusstsein für Ihren Körper zu kreiere und Ihnen helfen ihr Ziel zu erreichen. Sportler möchte ich helfen schneller und bewusster zu regenerieren.</p>
      </div>
      <article>
        <div>
          <h2>Ausbildung</h2>
          <p>Seit  Februar 2019 , Craniosacrale Therapie, KonMed Konstanzer Akademie für medizinische Weiterbildung, Deutschland</p>
          <p>Mai 2016,  20h FlyStrong Vinyasa & Arm Balance Evolution by Dylan Werner in Ubud, Bali</p>
          <p>April 2016, 200h Purna Yoga Teacher Training, Byron Bay Yoga Center in Byron Bay, Australien</p>
          <p>November 2014 – 2016, Manualtherapeutin, KonMed Konstanzer Akademie für medizinische Weiterbildung, Deutschland</p>
          <p>Mai 2010 – Juli 2013, Sporttherapeutin, Paracelsus Schule Zürich, Schweiz</p>
        </div>
        <div>
          <h2>Timeline</h2>
          <p>Seit August 2012 Eigene Praxis in Glattfelden.</p>
          <p>Von Januar 2013 bis März 2016 GC Mannschaftsbetreuern.</p>
          <p>
            Seit Oktober 2016  Mitglied der medizinischen Abteilung des Grasshopper Club Zürich derJugend Elite:
						<ul>
              <li>Verantwortlich für die Regeneration talentierter Nachwuchsspieler</li>
              <li>Behandeln und therapieren</li>
              <li>Optimieren der Regeneration</li>
              <li>Planen und unterstützen des täglichen Trainings.</li>
            </ul>
          </p>
          <p>Funktionaltraining (Cross Camp) Oktober 2016 Wellcomefit in Bülach.</p>
          <p>Betreuung von Sportler Martina Olympische Spiele 2012, Franco Marvulli 6 Tage Rennen, Philipp Amhof Vorbereitung auf RAAm 2019 und begleiten und betreuen am RAAM.</p>
          <p>Fussball: Bülach 3 Liega Mitglied der medizinischen Abteilung der ersten Mannschaft des FC Bülach.</p>
          <p>Eishockey GCK Lions Novize Elite Mitglied der medizinischen Abteilung der GCK Lions Jugend Elite Team.</p>
        </div>
      </article>
    </div>
  </Layout>
)

export default AboutPage
